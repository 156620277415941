/**
 * Mise en pace des event
 */
function faqinit(array_questions) {

    $(document).on('change keyup focus', '#faq_search', function () {

        showTabFaq();

        var search = $(this).val();

        if (search && search.length > 3) {

            $('.search_results li').show().removeClass("hasMatch");
            var array_match = [];
            
            // On affiche uniquement celles qui correspondent à la recherche
            $.each( array_questions, function( i, val ) {

                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());	
            
                if ( match ) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");

                    // Hide list of question if search active
                    $('#theme_page').hide();
                }

            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
                $('#theme_page').show();
            }
    
        } else {		
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
            $('#theme_page').show();
        }
    });
    
    var click_in_process = false;

    $('.search_results').mousedown(function() {
        click_in_process = true;
    });

    $('.search_results').mouseup(function() {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#faq_search').blur(function() {
        if(!click_in_process) {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
            $('#theme_page').show();
        }
    });

    $('#theme_global .theme').on('click', function() {
        var id = $(this).data('id');
        var theme = $('#theme_page');
        $('#theme_global').removeClass('actif');
        $('.title_detail_sub').css('display','none');
        theme.addClass('actif');
        showTabFaq(id, 1)
    });

    $('#theme_page .theme').on('click', function() {
        var id = $(this).data('id');
        showTabFaq(id, 1);
    });

}

function researchFaq(node_id, level, question_id) { 

    $('.search_results').hide();
    $('.search_results li').show().removeClass("hasMatch");
    $('#theme_page').show();

    if ($('#theme_global').hasClass("actif")) {
        $('#theme_global').removeClass('actif');
        $('#theme_page').addClass('actif');
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate({scrollTop: $(".faq_question_" + question_id + "").offset().top - 100}, 1000);

}